import { handleError } from "api/error/handle-error";
import { IClaimQRCodeResponse } from "api/loyalty/loyalty.interface";
import { AxiosResponse } from "axios";
import { baseApi } from "../base/base.api";

export const LOYALTY_API = {
  GIFT: "/v1/loyalty/buyer/gift",
  QR_CODE: "/v1/loyalty/merchant/place-qr", 
};

export const claimGift = async (body: {
  loyaltyProgramId: string | null;
  buyerId: string | null;
}): Promise<AxiosResponse<any>> => {
  try {
    const response = await baseApi.post<any>(LOYALTY_API.GIFT, body);
    console.log("Claim Gift Response:", response.data); 
    return response;
  } catch (error) {
    handleError(error);
    throw error; 
  }
};

export const claimQrCode = async (): Promise<AxiosResponse<IClaimQRCodeResponse>> => {
  try {
    const response = await baseApi.get<IClaimQRCodeResponse>(LOYALTY_API.QR_CODE);
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
