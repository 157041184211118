import { claimQrCode } from "api/loyalty/loyalty.api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./claim-qr-code.css";
import { IClaimQRCodeResponse } from "api/loyalty/loyalty.interface";
import CachedIcon from '@material-ui/icons/Cached';
import QRCode from "react-qr-code";

export const ClaimQrCode = () => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState<string>("");  
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchQRCode = async () => {
      await getQRCode();
      intervalId = setInterval(getQRCode, 5000);
    };

    fetchQRCode();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  async function getQRCode() {
    try {
      const res = await claimQrCode();
      const responseData = res.data || res; 
  
      if (!responseData || !responseData.encodedUrl) {
        console.error("API response does not contain encodedUrl", responseData);
        return;
      }
      setQrCode(responseData.encodedUrl);
    } catch (error) {
      console.error("Error fetching QR Code:", error);
    }
  }
  

  return (
    <div className="qr-loyalty-wrapper">
      <h3 className="qr-title">{t("qrLoyalty")}</h3>

      <div className="qr-code">
        {loading ? (
          <p className="loading-text">🔄 Loading.....</p>
        ) : error ? (
          <p className="error-text">❌ {error}</p>
        ) : (
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={qrCode}
            viewBox="0 0 256 256"
          />
        )}

        <p className="text-center">{t("qrChange")}</p>
        <div onClick={getQRCode} className="reset-btn">
          <CachedIcon />
        </div>
      </div>
    </div>
  );
};
