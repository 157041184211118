const dev = {
    NODE_ENV: "dev",
    REACT_APP_HERE_API_KEY: "4fBT38vopEOMyDJRm2NMFFPn8wsOOh7d-nSfkr8m3bQ",
    API_URL: "https://api-dev.shoopcity.com",
    STORAGE_SECRET_KEY: "SHOOPCITY_SECRET_DEV",
    VERSION: '1.0.5-test-1'
}

const staging = {
    NODE_ENV: "staging",
    REACT_APP_HERE_API_KEY: "4fBT38vopEOMyDJRm2NMFFPn8wsOOh7d-nSfkr8m3bQ",
    API_URL: "https://api-staging.shoopcity.com",
    STORAGE_SECRET_KEY: "SHOOPCITY_SECRET_DEV",
    VERSION: '1.0.5-test-1'
}

const prod = {
    NODE_ENV: "prod",
    REACT_APP_HERE_API_KEY: "4fBT38vopEOMyDJRm2NMFFPn8wsOOh7d-nSfkr8m3bQ",
    API_URL: "https://api.shoopcity.com",
    STORAGE_SECRET_KEY: "SHOOPCITY_SECRET_PROD",
    VERSION: '1.0.4'
}

const config = {
    ...(process.env.REACT_APP_STAGE === "production" ? prod : process.env.REACT_APP_STAGE === "staging" ? staging : staging),
};

if (config.NODE_ENV === "dev") console.log(config)
else console.log(prod.VERSION)

export default config
